var Nav = {
  doms: {},
  isItBiggie: window.innerWidth > 960,
  refreshInfoAboutBiggieBrowser: function refreshInfoAboutBiggieBrowser() {
    this.isItBiggie = window.innerWidth > 960;
  },
  toggleNavigation: function toggleNavigation() {
    if (this.doms.nav.classList.contains("nav--open")) {
      this.doms.nav.classList.remove("nav--open");

      if (!this.isItBiggie) {
        this.doms.main.style.height = "auto";
        this.doms.main.style.overflow = "hidden";
      }
    } else {
      this.doms.nav.classList.add("nav--open");

      if (!this.isItBiggie) {
        this.doms.main.style.height = this.doms.nav.clientHeight + "px";
        this.doms.main.style.overflow = "hidden";
      }
    }
  },
  toggleSubMenu: function toggleSubMenu(e) {
    var clickedElementParent = e.target.parentElement;
    var clickedElementSubNav =
      clickedElementParent.querySelector(".js-nav__sublist");

    if (clickedElementParent.classList.contains("nav__item--open")) {
      clickedElementParent.classList.remove("nav__item--open");
      clickedElementSubNav.style.maxHeight = "0px";
    } else {
      var heightOfChildren = 0;

      for (var i = 0; i < clickedElementSubNav.children.length; i++) {
        heightOfChildren +=
          clickedElementSubNav.children[i].getBoundingClientRect().height;
      }

      window.innerWidth < "960" &&
        clickedElementParent.classList.add("nav__item--open");
      clickedElementSubNav.style.maxHeight = heightOfChildren + "px";
    }
  },
  showThumbnail: function showThumbnail(mouseEnteredLink) {
    var image = new Image();

    image.addEventListener("load", function () {
      var thumbCreated = false;

      function createThumb() {
        var tempDivParagraph = document.createElement("p");
        tempDivParagraph.classList.add("thumb__text");
        tempDivParagraph.innerHTML =
          mouseEnteredLink.target.getAttribute("data-title");
        var tempDiv = document.createElement("div");
        tempDiv.className = "thumb";
        tempDiv.style.backgroundImage =
          "url(" + mouseEnteredLink.target.getAttribute("data-image") + ")";
        tempDiv.appendChild(tempDivParagraph);
        document.body.appendChild(tempDiv);
      }

      mouseEnteredLink.target.addEventListener(
        "mousemove",
        function (mouseMoveOnLink) {
          if (
            mouseEnteredLink.target === mouseMoveOnLink.target &&
            !thumbCreated
          ) {
            createThumb();
            thumbCreated = true;
          }
        }
      );
    });

    image.src = mouseEnteredLink.target.getAttribute("data-image");
  },
  hideThumb: function hideThumb() {
    var allThumbs = document.querySelectorAll(".thumb");
    for (var i = 0; i < allThumbs.length; i++) {
      if (
        allThumbs[i].style.backgroundImage.indexOf(
          allThumbs[i].style.backgroundImage
        ) !== 1
      ) {
        if ("remove" in Element.prototype) {
          allThumbs[i].classList.add("hide");
          allThumbs[i].addEventListener("animationend", function () {
            this.remove();
          });
        } else {
          allThumbs[i].parentNode.removeChild(allThumbs[i]);
        }
      }
    }
  },
  toggleFromOutsideOfNav: function toggleFromOutsideOfNav(e) {
    if (
      !e.target.matches(".js-nav *") &&
      this.doms.nav.classList.contains("nav--open") &&
      !e.target.matches(".js-toggle-nav *") &&
      !e.target.matches(".js-toggle-nav")
    ) {
      this.toggleNavigation();
    }
  },
  init: function init() {
    window.addEventListener("resize", this.refreshInfoAboutBiggieBrowser);

    this.doms = {
      toggleNav: document.querySelectorAll(".js-toggle-nav"),
      nav: document.querySelector(".js-nav"),
      linksNav: document.querySelectorAll(".js-nav__link"),
      linkWithChildren: document.querySelectorAll(".js-nav-dropdown"),
      main: document.querySelector(".main"),
    };

    for (var i = 0; i < this.doms.toggleNav.length; i++) {
      this.doms.toggleNav[i].addEventListener(
        "click",
        this.toggleNavigation.bind(this)
      );
    }

    // hide nav when click occurs outside of open navigation
    document.addEventListener("click", this.toggleFromOutsideOfNav.bind(this));

    for (var h = 0; h < this.doms.linkWithChildren.length; h++) {
      this.doms.linkWithChildren[h].addEventListener(
        "click",
        this.toggleSubMenu.bind(this)
      );
    }

    if (this.isItBiggie) {
      for (var n = 0; n < this.doms.linksNav.length; n++) {
        this.doms.linksNav[n].addEventListener(
          "mouseenter",
          this.showThumbnail.bind(this)
        );
      }
      for (var r = 0; r < this.doms.linksNav.length; r++) {
        this.doms.linksNav[r].addEventListener(
          "mouseout",
          this.hideThumb.bind(this)
        );
      }
    }
  },
};
