var Timeline = {
  doms: {
    circles: jQuery('.timeline__circle'),
    triggers: jQuery('.timline__trigger'),

    circle1: jQuery('.js-circle1'),
    circle2: jQuery('.js-circle2'),
    circle3: jQuery('.js-circle3'),
    circle4: jQuery('.js-circle4'),
    circle5: jQuery('.js-circle5'),
    circle6: jQuery('.js-circle6'),
    circle7: jQuery('.js-circle7'),
    trigger1: jQuery('.js-trigger1'),
    trigger2: jQuery('.js-trigger2'),
    trigger3: jQuery('.js-trigger3'),
    trigger4: jQuery('.js-trigger4'),
    trigger5: jQuery('.js-trigger5'),
    trigger6: jQuery('.js-trigger6'),
    trigger7: jQuery('.js-trigger7')
  },
  hideAllCircles: function hideAllCircles() {
    jQuery(this).fadeOut(140);
  },
  init: function init() {

    var that = this;

    jQuery(this.doms.trigger1).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle1.fadeIn(140);
    });
    jQuery(this.doms.trigger2).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle2.fadeIn(140);
    });
    jQuery(this.doms.trigger3).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle3.fadeIn(140);
    });
    jQuery(this.doms.trigger4).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle4.fadeIn(140);
    });
    jQuery(this.doms.trigger5).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle5.fadeIn(140);
    });
    jQuery(this.doms.trigger6).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle6.fadeIn(140);
    });
    jQuery(this.doms.trigger7).click(function () {
      that.doms.circles.each(that.hideAllCircles);
      that.doms.circle7.fadeIn(140);
    });

    that.doms.circles.click(function() {
      that.doms.circles.each(that.hideAllCircles);
    });

  }
};
