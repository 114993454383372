var Carousel = {
  doms: {
    carouselSelectors: document.querySelectorAll('.js-carousel'),
    // prev: document.querySelector('.js-carousel__nav--prev'),
    // next: document.querySelector('.js-carousel__nav--next'),
  },
  carousel: null,
  disableNavigationArrow: function disableNavigationArrow() {
    if (this.currentSlide === 0) {
      this.prevArrow.classList.add('carousel__nav--inactive');
    }
    else {
      this.prevArrow.classList.remove('carousel__nav--inactive');
    }

    if (this.currentSlide === this.innerElements.length - 1) {
      this.nextArrow.classList.add('carousel__nav--inactive');
    }
    else {
      this.nextArrow.classList.remove('carousel__nav--inactive');
    }
  },
  init: function init() {
    if (this.doms.carouselSelectors) {

      // Extend prototype with method that adds arrows to DOM
      // Style the arrows with CSS or JS — up to you mate
      Siema.prototype.addArrows = function () {

        // make buttons & append them inside Siema's container
        this.prevArrow = document.createElement('button');
        this.prevArrow.classList.add('carousel__nav', 'carousel__nav--prev');
        this.nextArrow = document.createElement('button');
        this.nextArrow.classList.add('carousel__nav', 'carousel__nav--next');

        this.selector.parentNode.insertBefore(this.prevArrow, this.selector.nextSibling);
        this.selector.parentNode.insertBefore(this.nextArrow, this.selector.nextSibling);

        // event handlers on buttons
        var self = this;
        this.prevArrow.addEventListener('click', function() {
          self.prev();
        });
        this.nextArrow.addEventListener('click', function() {
          self.next();
        });
      };

      for (var i = 0; i < this.doms.carouselSelectors.length; i++) {
        var instance = new Siema({
          selector: this.doms.carouselSelectors[i],
          draggable: false,
          onChange: this.disableNavigationArrow,
        });
        instance.addArrows();
        this.disableNavigationArrow.call(instance);
      }

    }
  }
};
