var News = {
  doms: {
    news: document.querySelectorAll('.js-news__info'),
  },
  maxHeight: 0,
  matchHeight: function matchHeight() {

    for (var index = 0; index < this.doms.news.length; index++) {
      var element = this.doms.news[index];
      element.style.height = 'auto';
    }

    // if there is one column use auto height
    if (window.innerWidth > 668) {
      // helper function because i cannot use Math.max(...heights) in ES5
      var getMaxOfArray = function(numArray) {
        return Math.max.apply(null, numArray);
      };

      // reset values
      var heights = [];
      this.maxHeight = 0;

      // get hights of all news
      for (var indexx = 0; indexx < this.doms.news.length; indexx++) {
        var elementt = this.doms.news[indexx];
        heights.push(elementt.getBoundingClientRect().height);
      }


      // determine the talles item
      this.maxHeight = getMaxOfArray(heights);

      // match items height
      for (var indexxx = 0; indexxx < this.doms.news.length; indexxx++) {
        var elementtt = this.doms.news[indexxx];
        elementtt.style.height = this.maxHeight + 'px';
      }
    }
  },
  init: function init() {
    if (!this.doms.news) {
      return;
    }

    var self = this;
    jQuery(document).ready(function () {
      self.matchHeight();
    });

    window.addEventListener('resize', throttle(this.matchHeight, 280, this));
  }
};
