var Siri = {
  siri: document.querySelectorAll('.js-siri'),
  siriWrapTextInSpans: function siriWrapTextInSpans(el) {
    var text = el.textContent;
    var words = text.split(' ');
    el.innerHTML = '';

    words.forEach(function(word, index) {
      // I use 140ms as a global value for all transitions, animations and delay
      var span = document.createElement('span');
      var delay = 140 / 2 * index;
      span.className += 'siri';
      span.innerHTML = word + '&nbsp;';
      span.style.animationDelay = delay.toString(10) * 2 + 'ms';
      el.appendChild(span);
    });
  },
  siriAnimate: function siriAnimate() {
    for(var i = 0; i < this.siri.length; i++) {
      this.siriWrapTextInSpans(this.siri[i]);
    }
  }
};

Siri.siriAnimate();
