var Questions = {
  doms: {
    accordions: document.querySelectorAll(".accordion"),
    titles: document.querySelectorAll(".accordion__title"),
  },
  isExpanded: function isExpanded(element) {
    const ret = element.classList.contains("accordion--open") ? true : false;
    return ret;
  },
  closeAll: function closeAll(element) {
    const currentAccordion = element;
    this.doms.accordions.forEach(function (accordion) {
      // Remove 'accordion--open' class
      if (accordion.id != currentAccordion.id) {
        accordion.classList.remove("accordion--open");
      }
    });
  },
  toggleClass: function toggleClass() {
    const accordion = target.parentElement;
    accordion.classList.toggle("accordion--open");
  },
  handleToggle: function handleToggle(event) {
    const target = event.currentTarget;
    const accordion = target.parentElement;
    const accordionInner = target.nextElementSibling;

    // close all accordions
    this.closeAll(accordion);
    // toggle "accordion--open" class
    accordion.classList.toggle("accordion--open");
    // toggle 'aria-expanded' attribute
    accordionInner.setAttribute("aria-expanded", this.isExpanded(accordion));
  },
  init: function init() {
    this.doms.titles.forEach(
      function (title) {
        // Event listener: Click to toggle accordion
        title.addEventListener("click", this.handleToggle.bind(this));
        // Event listener: 'Enter' key to toggle accordion
        title.addEventListener(
          "keyup",
          function (event) {
            event.key === "Enter" && this.handleToggle(event);
          }.bind(this)
        );
      }.bind(this)
    );
  },
};
