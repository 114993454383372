// On hero__scroll click, smoothly scroll down below the hero element
var Hero = {
	doms: {
		hero: document.querySelector(".js-hero"),
		heroScrollBrn: document.querySelector(".js-hero__scroll"),
		video: document.querySelector(".js-hero__video"),
		heroMiddle: document.querySelector(".js-hero__middle"),
		circle: document.querySelector(".hero__circle"),
	},
	isItiOS: /iPad|iPhone|iPod/.test(navigator.platform),
	iOSverion: function iOSverion() {
		var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
		return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	},
	middleCenterPoint: 0,
	recalculateMiddleCenterPoint: function recalculateMiddleCenterPoint() {
		var middleGBCR = this.doms.heroMiddle.getBoundingClientRect();
		this.middleCenterPoint = middleGBCR.top + middleGBCR.height / 2;
	},
	reduceOpacity: function reduceopacity() {
		if (!this.isItiOS) {
			this.doms.heroMiddle.style.opacity =
				1 - window.pageYOffset / this.middleCenterPoint;
		}
	},
	init: function init() {
		var self = this;

		if (this.doms.hero) {
			// recuce opacity of hero middle section on scroll
			this.recalculateMiddleCenterPoint();
			window.addEventListener(
				"scroll",
				throttle(this.recalculateMiddleCenterPoint, 280, this)
			);
			window.addEventListener("scroll", this.reduceOpacity.bind(this));
		}

		if (this.doms.hero && this.isItiOS) {
			this.doms.heroMiddle.style.position = "absolute";
		}

		if (this.doms.heroScrollBrn) {
			this.doms.heroScrollBrn.addEventListener("click", function () {
				jQuery("html, body").animate(
					{
						scrollTop: self.doms.hero.clientHeight,
					},
					200
				);
			});
		}

		if (this.doms.video && this.isItiOS) {
			if (this.iOSverion()[0] < 10) {
				this.doms.hero.style.backgroundImage =
					'url("' + this.doms.video.poster + '")';
				this.doms.video.style.display = "none";
			}
		}

		// of course we need paralax here :-/
		if (this.doms.video) {
			window.addEventListener("scroll", function () {
				const currentScroll = window.pageYOffset;
				const windowHeight = window.innerHeight;

				var progress = currentScroll / windowHeight / 3 + 1;

				self.doms.video.style.transform =
					"translateX(-50%) translateY(-50%) scale(" + progress + ")";
			});
		}

		// Commented out as for some reason hero__circle was hidden behind next section's up slash
		// Changed z-index to fix bug but causing bug with parallax

		// this 1440 is a matts magic number, no clue why 1440
		// if (this.doms.circle && window.innerWidth > 1440) {
		// 	window.addEventListener("scroll", function () {
		// 		const currentScroll = window.pageYOffset;
		// 		const windowHeight = window.innerHeight;

		// 		var progress = (currentScroll / windowHeight / 4) * windowHeight;

		// 		self.doms.circle.style.transform = "translateY(" + progress + "px)";
		// 	});
		// }
	},
};
