var Layers = {
  doms: {
    layers: document.querySelectorAll(".js-layer"),
  },

  // info needed to proceed calculations
  layersInfo: [],
  viewPortHeight: null,

  // override height and context point of each layer element
  // should be triggered on browser resize & load
  refreshLayersInfo: function refreshLayersInfo() {
    this.layersInfo = [];
    for (var i = 0; i < this.doms.layers.length; i++) {
      this.layersInfo.push({
        el: this.doms.layers[i],
        height: this.doms.layers[i].offsetHeight,
        context:
          this.doms.layers[i].offsetHeight >= this.viewPortHeight
            ? this.doms.layers[i].offsetTop
            : this.doms.layers[i].offsetTop -
              (this.viewPortHeight - this.doms.layers[i].offsetHeight),
      });
    }
  },

  // override value of viewPortHeigh
  // should be triggered on browser resize
  refreshBrowserSize: function refreshBrowserSize() {
    this.viewPortHeight = window.innerHeight;
  },

  // animate layes acordingly to its positions
  // find a difference between elements context and current position
  // make the animation more or less prominent (devide by 2 in this example)
  // edit only next elements, previous should stay untransformed
  refreshLayersPosition: function refreshLayersPosition() {
    var currentPosition = window.pageYOffset;

    // idex 0 stays still and works as a paralaxxing context for second layed and next ones
    for (var i = 1; i < this.layersInfo.length; i++) {
      if (this.layersInfo[i].context > currentPosition) {
        this.layersInfo[i].el.style.transform =
          "translate3d(0, " +
          (currentPosition - this.layersInfo[i].context) * 0.25 +
          "px, 0)";
      } else {
        this.layersInfo[i].el.style.transform = "none";
      }
    }
  },
  init: function () {
    if (this.doms.layers) {
      var self = this;
      this.viewPortHeight = window.innerHeight;

      this.refreshLayersInfo();

      window.addEventListener("load", function () {
        self.refreshBrowserSize();
        self.refreshLayersInfo();
        self.refreshLayersPosition();
      });

      window.addEventListener("resize", function () {
        self.refreshBrowserSize();
        self.refreshLayersInfo();
      });

      window.addEventListener("scroll", function () {
        self.refreshLayersPosition();
      });
    }
  },
};
