var FeatureDetection = {
  init: function init() {

    // ios
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      document.body.classList.add('ios');
    }

  }
};
