var Intro = {
  doms: {
    intro: document.querySelector('.js-intro'),
    video: document.querySelector('.js-intro__video'),
  },
  showintro: function showintro() {
    this.doms.intro.classList.add('intro--show');
  },
  hideintro: function hideintro() {
    this.doms.intro.classList.add('intro--hide');
  },
  fadeOutIntro: function fadeOutIntro() {
    this.doms.intro.classList.add('intro--fadeOut');
    this.doms.intro.addEventListener('animationend', this.hideintro.bind(this));
  },
  isItiOS: /iPad|iPhone|iPod/.test(navigator.platform),
  animateIntro: function animateIntro() {
    this.showintro();
    var that = this;

    // store info about watched intro in localStorage
    try {
      localStorage.setItem('userSawIntro', 'true');
    }
    catch (err) {
      console.warn(err);
    }

    setTimeout(function() {
      that.fadeOutIntro();
    }, 3500);
  },
  init: function init() {
    if (this.doms.intro) {
      if (localStorage.getItem('userSawIntro') === 'true') {
        return;
      }

      this.animateIntro();
      if (this.isItiOS) {
        this.doms.video.style.display = 'none';
      }
    }
  }
};
